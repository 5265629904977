import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import RuntimeSettingsWrapper from "RuntimeSettingsWrapper";
import singleSpaReact from "single-spa-react";
import App from "./App"; // <-- This must be the main app of your application - Should be the same as in your index.tsx
import "./index.scss";

window.paVersion = () => {
  const version = "2.63.03";

  // eslint-disable-next-line no-console
  console.log(`VERSION: [${version}]`);
};

type MfeAppProps = {
  themeService: unknown;
};

const MfeApp = (props: MfeAppProps) => {
  return (
    <RuntimeSettingsWrapper>
      <BrowserRouter>
        <App themeService={props.themeService} />
      </BrowserRouter>
    </RuntimeSettingsWrapper>
  );
};

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: MfeApp,
  errorBoundary(err, info, props) {
    return <div>This renders when a catastrophic error occurs</div>;
  }
});

let styleElements: Array<Element>;

export const bootstrap = reactLifecycles.bootstrap;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mount = [
  // Add CSS styles to the page before React app is loaded
  async () => {
    if (styleElements) {
      document.head.append(...styleElements);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any) => {
    if (!props?.permissions?.prioritizedAgenda) {
      props.singleSpa.navigateToUrl("/unauthorized");
    }
    return reactLifecycles.mount(props);
  },
  // Keep references to added style tags for the app so they can be remounted again
  async () => {
    if (!styleElements) {
      styleElements = Array.from(
        document.getElementsByClassName("amgen-rtsensing-prioritized-agenda")
      );
    }
  }
];
export const unmount = [
  reactLifecycles.unmount,
  // Remove styles once the app has unmounted
  async () => {
    styleElements.forEach(element => element.remove());
  }
];
